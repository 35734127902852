
function App() {
  return (
    <div className="App">
      <header className="App-header" itemscope itemtype="https://schema.org/Product">

        <h1 className='title' itemprop="name">Электро самокат NoNaMe.</h1>

        <ul className="list-title">
          <li> <a href='#description'><strong>Описание</strong></a> </li>
          <li> <a href='#advantages'><strong>Преимущества</strong></a> </li>
          <li> <a href='#characteristics'><strong>Характеристика</strong></a> </li>
          <li> <a href='#equipment'><strong>Комплектация</strong></a> </li>
          <li> <a href='#buy'><strong>Покупка</strong></a> </li>
          <li> <a href='#min-characteristics'><strong>Основные характеристики</strong></a> </li>
        </ul>

        <div className="image">
          <picture className="image__picture">
            <source media="(min-width: 1150px)" srcSet="images/noname1.png 1x, images/noname1.png 2x" />
            <source media="(min-width: 768px)" srcSet="images/noname1.png 1x, images/noname1.png 2x" />
            <img className="image__img" src="images/noname1.png" srcSet="images/noname1.png 2x" alt="Электро самокат NoNaMe" itemprop="image" />
          </picture>
        </div>

        <section id="description" >
          <h2>
            Описание Электро самоката Нонаме
          </h2>
          <p className='text' itemprop="description">
            Электро самокат <strong>NoNaMe</strong> – новинка 2023 года. Мощные мотор-колеса, надежная рычажная подвеска.
            безлюфтовый руль и другие преимущества обеспечили ему высокую популярность. Все характеристики <strong>NoNaMe</strong> говорят о том,
            что эта модель является одним из мощных, но в то же время доступных по цене электро самокатов на рынке.
            <br></br>
            <br></br>
            Электро самокат <strong>NoNaMe</strong> – произведён в России.
            <br></br>
            Сделан для российских дорог.
            <br></br>
            <strong className='text__red'>Не требует водительское удостоверения</strong>
          </p>
        </section>
      </header>
      <main className="main">
        <section className='description'>

          <h2 id="advantages">Преимущества <strong>NoNaMe</strong> 118w*2</h2>
          <p className='text'>  О самокате <strong>NoNaMe</strong> отзывы самые хорошие по многим причинам. Среди его преимуществ:</p>
          <ul>
            <li><strong>NoNaMe</strong>, можно поставить сиденье. </li>
            <li>Так же в задней части самоката есть упор для ноги, он будет удобен, если вы предпочитаете передвигаться стоя на самокате.</li>
            <li> Самокат оснащен одним зарядным устройствам на 3A.</li>
            <li>Мотор-колеса на данной модели (спереди и сзади) имеют мощность 118w*2 (236w).</li>
            <li>Огромная мощность и плавный старт</li>
            <li>Новая и мощная амортизационная система</li>
            <li>Покрышки стоят камерные.</li>
            <li>Можно поставить 11-14 дюймовые покрышки.</li>
            <li>Самокат оснащен двумя контроллерами на 25A.</li>
            <li>Руль не имеет люфт. Нескладной.</li>
            <li>Подвеска рычажного типа + регулируемые амортизаторы.</li>
            <li>Самокат имеет мощную LED фару, а так же дополнительные диоды освещения, находящиеся в деке и ферме электро самоката.</li>
            <li>На одном заряде, <strong>NoNaMe</strong> проедет до 65км.</li>
            <li>Настройку режима езды Вы сможете произвести в компьютере самоката.</li>
            <li>Самокат имеет АКВА-ЗАЩИТУ. Контролеры находятся в компаунде.</li>
            <li>Батарея самоката в водонепроницаемом кейсе.</li>
            <li>Вся проводка на АКВА переходниках и сведена к минимуму.</li>
          </ul>
          <p className='text'></p>
          <p className='text'></p>
          <p className='text'></p>
          <p className='text'></p>
          <p className='text'></p>

        </section>

        <section id="gallery">
          <h2> Галлерея </h2>
          <div className="gallery__wrapper">

            <div className="swipper">

              <div className="swipper__wrapper">
                <div className="swipper__slide">слайд1</div>
                <div className="swipper__slide">слайд2</div>
                <div className="swipper__slide">слайд3</div>
                <div className="swipper__slide">слайд4</div>
              </div>

              <div className="swipper__next">+</div>
              <div className="swipper__prev">-</div>
              <div className="swipper__pagination">пагинация</div>
            </div>

          </div>
        </section>

        <section id="characteristics">
          <h2>ХАРАКТЕРИСТИКИ ЭЛЕКТРО САМОКАТА <strong>NoNaMe</strong>:</h2>

          <ul>
            <li>Полный привод.</li>
            <li>Мощность мотор-колеса 118w (суммарно 236w), диаметр 10дюймов.</li>
            <li>Магниты 5см.</li>
            <li>Контроллер 52V 25A.</li>
            <li>Максимальная скорость до 25км/час.</li>
            <li>Максимальное расстояние на одном заряде до 65 км.</li>
            <li>Вес - <i>в зависимости от комплектации.</i></li>
            <li>Грузоподъёмность 200 кг.</li>
            <li>Аква-защита с завода.</li>
            <li>Батарея 52V25A MADE IN TAIWAN имеет полную сертификацию ГОСТ, а так же батарея имеет балансир.</li>
            <li>Безлюфтовая рычажная подвеска + регулируемые амортизаторы воздушного и масляного типа.</li>
            <li>2 выхода для зарядного устройства, одно зарядных устройства.</li>
            <li>Умная BMS плата с автоматическим предохранителем от перезагрузки батареи, двигателя.</li>
            <li>Руль безлюфтовой, цельный.</li>
            <li>Сиденье с внутренним амортизатором.</li>
            <li>Задний упор.</li>
            <li>Алюминиевая рама.</li>
          </ul>
        </section>



        <section id="equipment">
          <h2>Комплектация:</h2>
          <ul>
            <li>самокат 1шт.</li>
            {/* <li>документы + инструкция 1 шт.</li> */}
            <li>набор инструментов 1 комплект</li>
            <li>зарядное устройство 1шт.</li>
            <li>сиденье</li>
          </ul>
        </section>





        <section id="buy">

          <h2>ПОКУПКА САМОКАТА <strong>NoNaMe</strong></h2>
          <p>
            В нашем магазине оригинальный самокат <strong>NoNaMe</strong> купить можно по минимальной цене, от производителя. При этом на самокат дается гарантия до 3-х лет.
            Данная модель является прямым конкурентом любого электро самоката. Покупайте отличный современный электротранспорт для бездорожья и города!
          </p>
        </section>




        <section className='characteristics'>
          <div>
            <h2 id="min-characteristics">Основные характеристики</h2>
            <ul className='ul-row'>
              <li className='row'>
                <div>Размер колес</div>
                <div>10"</div>
              </li>
              <li className='row'>
                <div>Скорость</div>
                <div>До 25 км/ч</div>
              </li>
              <li className='row'>
                <div>Расстояние</div>
                <div>До 65 км</div>
              </li>
              <li className='row'>
                <div>Вес райдера</div>
                <div>200 кг</div>
              </li>
              <li className='row'>
                <div>Мощность</div>
                <div>112*2 Вт</div>
              </li>
              <li className='row'>
                <div>Время зарядки</div>
                <div>10 часов</div>
              </li>
              <li className='row'>
                <div>Батарея</div>
                <div>Литиевая, 52v 25ah</div>
              </li>
              <li className='row'>
                <div>Амортизация</div>
                <div>Двухподвес</div>
              </li>
              <li className='row'>
                <div>Рама</div>
                <div>Алюминиевая</div>
              </li>
              <li className='row'>
                <div>Тормоза</div>
                <div>Дисковые гидравлические + электронные</div>
              </li>
              <li className='row'>
                <div>Вес</div>
                <div>В зависимости от комплектации</div>
              </li>
            </ul>

            <h3>Дополнительно</h3>
            <ul>
              <li className='row'>
                <div>Дисплей</div>
                <div>Есть, цветной</div>
              </li>
              <li className='row'>
                <div>Фары</div>
                <div>Передняя Arctic</div>
              </li>
              <li className='row'>
                <div>Сидение</div>
                <div>Есть</div>
              </li>
              <li className='row'>
                <div>Подножка</div>
                <div>Есть</div>
              </li>
              <li className='row'>
                <div>Приложение APP</div>
                <div>Есть</div>
              </li>
            </ul>
          </div>
        </section>


      </main>

      <footer className="footer">

      </footer>
    </div>
  );
}

export default App;
